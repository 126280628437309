import React, { useEffect, useRef, useState } from "react";
import { AiFillPhone } from "react-icons/ai";
// import { FaSignInAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import logo from "/src/assets/images/logolifelineachham.png";
import logo from "../../assets/images/LifelineLogo.png";

import { FiMail } from "react-icons/fi";
import { removeUser } from "../../features/user/userSlice";
const TopBar = () => {
  const { first_name } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState({
    apply: false,
    profile: false,
  });
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const handleButtonClick = (type) => {
    setShowOptions((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions((prev) => ({
        ...prev,
        apply: false,
      }));
    }

    if (dropdownRef2.current) {
      if (!dropdownRef2.current.contains(event.target)) {
        setShowOptions((prev) => ({
          ...prev,
          profile: false,
        }));
      }
    }
  };

  const handleLogout = () => {
    dispatch(removeUser());
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-between items-center px-3 py-2 md:px-4">
      <div className="flex items-center">
        {/* <Link to="/">
          <h1 className="text-xl font-bold text-gray-600">LifeLine Achham</h1>
        </Link> */}
        <Link to="/">
          {/* <img
            className=""
            src="https://scontent.xx.fbcdn.net/v/t1.15752-9/394013143_1402892150263947_4017802105486098215_n.png?_nc_cat=102&ccb=1-7&_nc_sid=510075&_nc_ohc=STWATeEFcZkAX90VNUP&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdR0FGBIHXuf7MF2UUHpVX4S3di3crPoPtgh3eV2F8f7Lw&oe=655CF888"
            alt=""
          /> */}
          <img
            src={logo}
            alt="Logo of lifeline Achham"
            className="h-8 w-full object-contain sm:h-10 md:h-12"
          />
        </Link>
      </div>
      <div className="flex items-center gap-3">
        <div className="hidden md:flex items-center px-3">
          <FiMail size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700"> support@lifelineachham.org</p>
        </div>
        {/* ------------------------------------------------------------------------------ */}
        <div className="hidden md:flex items-center px-3">
          <AiFillPhone size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700"> 9810228669</p>
        </div>

        <div className="flex gap-3 items-center">
          {!first_name ? (
            <Link to="/login" onClick={() => setShowOptions(false)}>
              <button className="text-sm rounded-lg border-none bg-none text-primary-dark font-bold p-0">
                Log In
              </button>
            </Link>
          ) : (
            <Link to="/" onClick={handleLogout} className="">
              <button className="text-sm rounded-lg border-none bg-none text-gray-700 font-bold p-0 hover:text-primary-dark">
                Logout
              </button>
            </Link>
          )}
          <div className="relative" ref={dropdownRef}>
            <button
              className="rounded-lg text-sm p-2 md:p-3"
              onClick={() => handleButtonClick("apply")}
            >
              Apply Now
            </button>

            {showOptions.apply && (
              <div className="absolute top-full right-0 mt-2 min-w-[200px] bg-white border overflow-hidden border-gray-300 rounded-lg shadow-md z-50">
                <Link
                  to={`${first_name ? "/apply-form" : "/login"}`}
                  state={{ value: "doner" }}
                  className="block hover:bg-gray-200 p-2 border-b"
                  onClick={() => setShowOptions(false)}
                >
                  Apply as Blood Donor-Inside Achham
                </Link>

                <Link
                  to={`${first_name ? "/apply-form" : "/login"}`}
                  state={{ value: "volunteer" }}
                  className="block hover:bg-gray-200 p-2"
                  onClick={() => setShowOptions(false)}
                >
                  Apply as Volunteer- Outside Achham
                </Link>
              </div>
            )}
          </div>
          {/* {first_name && (
            <div>
              <div className="relative" ref={dropdownRef2}>
                <h1
                  className="uppercase"
                  onClick={() => handleButtonClick("profile")}
                >
                  <span>Hi </span>
                  <b className="text-[var(--primary-dark)]">{first_name}</b>
                </h1>
                {showOptions.profile && (
                  <ul className="absolute top-full right-0 mt-2 min-w-[100px] bg-white border overflow-hidden border-gray-300 rounded-lg shadow-md z-50">
                    <li className="p-2 font-semibold border-b">
                      <Link to="/profile">Profile</Link>
                    </li>
                    <li className="p-2 font-semibold">
                      <Link to="" onClick={handleLogout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
