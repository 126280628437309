import axios from "axios";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const BlogDetailPage = () => {
  // Sample blog data (replace with your actual data)

  const location = useLocation();

  // const params = useParams();

  const { date, name, key, imageSrc, title, description } =
    location.state || {};

  const [latestBlogs, setLatestBlogs] = useState([]);

  useState(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/blogs/?ordering=-created_at`)
      .then((response) => {
        console.log(response.data);
        setLatestBlogs(response.data.filter((item) => item.title !== title));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="py-8 sm:py-12 md:py-16">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
          <div className="md:col-span-2 ">
            {/* Main Content */}
            <img
              src={imageSrc}
              alt={title}
              className="w-full  rounded-md mb-4 "
            />
            <h1 className="text-3xl font-bold mb-2">{title}</h1>

            <p className="text-lg text-gray-800">{description}</p>
            <div className="text-right text-gray-500 ">- {name}</div>
          </div>
          <aside className="md:col-span-1 gap-4 bg-gray-100 rounded-md hover:red">
            {/* Sidebar */}

            <div className="bg-gray-100 p-4 my-4">
              <div className="border-l-2 border-gray-400 pl-6 pt-4 pb-2">
                <h3 className="text-xl font-bold mb-2">Latest Blogs:</h3>
                {latestBlogs.map((blog) => (
                  <div className="mb-4 border-b-2 border-gray-400 pb-4 last:border-0">
                    <Link
                      to={`/blogs/blogDetail/${blog.name}`}
                      state={{
                        date: new Date(blog.created_at).toLocaleDateString(
                          "en-US",
                          { year: "numeric", month: "long", day: "numeric" }
                        ),
                        name: blog.author,
                        key: blog.title,

                        imageSrc: blog.thumbnail,
                        title: blog.title,

                        description: blog.content,
                      }}
                      className="text-lg font-semibold flex items-center"
                    >
                      <img
                        src={blog.thumbnail}
                        alt={blog.title}
                        className="w-[100px] h-[70px] object-cover mr-3"
                      />
                      <div className="inline-block w-full">
                        {blog.title}
                        <p className="text-sm text-gray-600">
                          {blog.content.slice(0, 65) + "..."}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPage;
