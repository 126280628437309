import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const setting = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SponsoredSection = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/sponsors/`)
      .then((response) => {
        console.log(response);
        setImages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <h2 className="text-3xl font-bold text-center mb-4  text-blue-600">
          Proud Sponsors of Lifeline Achham
        </h2>
        <div className="-mx-3">
          <Slider {...setting}>
            {images.map((item) => (
              <div key={item.id}>
                <div className="mx-3 rounded-md overflow-hidden lg:mx-3 ">
                  <Link to={item.link} target="null">
                    <img
                      src={item.image}
                      // src={`${process.env.REACT_APP_API_URL}/store/${item.image}`}
                      alt={item.name}
                      className="w-full object-cover"
                    />
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SponsoredSection;
