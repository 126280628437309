import React from "react";
import { Link } from "react-router-dom";
import GalleryDetailPage from "./GalleryDetailPage";
const GalleryCard = ({ date, imageSrc, title, gallery }) => {
  const showGalleryImages = () => {
    return <GalleryDetailPage imagess={gallery.images} />;
  };

  return (
    <div
      className="mb-2 relative rounded overflow-hidden shadow-lg bg-slate-400 group"
      onClick={showGalleryImages}
    >
      <Link to={`/gallery-images/${gallery.id}`}>
        <img
          className="w-full h-56 object-cover cursor-pointer duration-200 ease-in-out group-hover:scale-[1.05] group-hover:rotate-1"
          src={imageSrc}
          alt="Blog post"
        />
        <div className="absolute bottom-0 flex flex-col left-0 h-[110px] w-full bg-primary-dark text-white text-center py-2 translate-y-[75px] group-hover:translate-y-0 transition-transform duration-300">
          <span className="block text-gray-100 text-sm font-bold border-b border-dashed pb-2 border-gray-300">
            {date}
          </span>
          <div className="flex-1">
            <h3 className="h-full flex flex-col items-center justify-center text-md px-3 font-semibold line-clamp-2">
              {title}
            </h3>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GalleryCard;
