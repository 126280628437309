import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom"; // Import BrowserRouter here

import BlogDetailPage from "../components/BlogSection/BlogDetailPage";
import BloodDonorForm from "../components/DonorAndVolunteerSection/BloodDonorForm";
import DonorAndVolunteerDropDownSection from "../components/DonorAndVolunteerSection/DonorAndVolunteerDropDownSection";
import DonorTillDate from "../components/DonorTillDate";
import LoginSignup from "../components/LoginSignup";
import PasswordResetPage from "../components/PasswordResetPage";
import RecentDoner from "../components/RecentDonors";
// terms and condition &privacy and policy
import FAQ from "../components/FAQ";
import PrivacyAndPolicy from "../components/PrivacyAndPolicy";
import TermsAndCondition from "../components/TermsAndCondition";

import VolunteerForm from "../components/DonorAndVolunteerSection/VolunteerForm";
import GalleryDetailPage from "../components/ImageGallerySection/GalleryDetailPage";
import MunicipalityVdcs from "../components/MunicipalityVdcsSection";
import VolunteerSection from "../components/VolunteerSection";
import AboutUs from "../pages/AboutUs";
import Blogs from "../pages/Blogs";
import BloodDoners from "../pages/BloodDoners";
import ContactUs from "../pages/ContactUs";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";

import AssociatePartners from "../components/AssociatePartners";
import EmergencyDonors from "../components/EmergencyDonors";
import FuturePRojectlifelineachham from "../components/FuturePRojectlifelineachham";
import HospitalDoner from "../components/HospitalDonorSection";
import { ApplyForm } from "../components/DonorAndVolunteerSection/ApplyForm";
import TeamSection from "../components/TeamSection/TeamSection";

const Routers = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Use BrowserRouter as the main Router */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/about/:page" element={<TeamSection />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blooddoner" element={<BloodDoners />} />
        {/* <Route path="/blogs/blogDetail/:name" element={<BlogDetailPage />} /> */}

        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/apply-volunteer" element={<VolunteerForm />} />

        <Route path="/recentdonors" element={<RecentDoner />} />
        <Route path="/donortilldate" element={<DonorTillDate />} />

        {/* volunteer for the achham district */}
        <Route path="/volunteer" element={<VolunteerSection />} />
        {/* Donor and volunteer form */}
        <Route path="/apply-volunteer" element={<VolunteerForm />} />
        <Route path="/apply-form" element={<ApplyForm />} />
        <Route path="/apply-achhamblooddonor" element={<BloodDonorForm />} />
        <Route
          path="/donorandvolunteersection"
          element={<DonorAndVolunteerDropDownSection />}
        />
        <Route path="/termsandcondition" element={<TermsAndCondition />} />
        <Route path="/privacyandpolicy" element={<PrivacyAndPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route
          path="/futureproject"
          element={<FuturePRojectlifelineachham />}
        />

        {/* Added by Krishna */}
        {/* <Route path="/municipalityvdc/:name" element={<MunicipalityVdcs />} /> */}
        <Route
          path="/municipalityvdc/:name/:id"
          element={<MunicipalityVdcs />}
        />
        <Route path="/gallery-images/:id" element={<GalleryDetailPage />} />
        <Route path="/blogs/blogDetail/:id" element={<BlogDetailPage />} />
        <Route path="/login" element={<LoginSignup />} />
        <Route path="/passwordreset" element={<PasswordResetPage />} />
        <Route path="/emergencydonor/:id" element={<EmergencyDonors />} />
        <Route
          path="/associatedonergroup/:id"
          element={<AssociatePartners />}
        />
        <Route path="/hospitaldonor/:id" element={<HospitalDoner />} />

        {/* not found page */}
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </>
  );
};

export default Routers;
