import React, { useState, useMemo, useEffect } from "react";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

export default function Pagination({ data, setData, pageSize }) {
  const [currentPage, setCurrentPage] = useState(1);

  const pagesCount = useMemo(
    () => Math.ceil(data.length / pageSize),
    [data, pageSize]
  );

  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
  }, [data, currentPage, pageSize]);

  useEffect(() => {
    setData(currentPageData);
  }, [currentPage]);

  return (
    <nav aria-label="Pagination navigation">
      <MDBPagination end className="mb-0">
        {/* Previous Page */}
        <MDBPaginationItem disabled={currentPage === 1}>
          <MDBPaginationLink
            role="button"
            aria-label="Previous page"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            «
          </MDBPaginationLink>
        </MDBPaginationItem>

        {/* Page Numbers */}
        {[...Array(pagesCount)].map((_, index) => (
          <MDBPaginationItem key={index} active={currentPage === index + 1}>
            <MDBPaginationLink
              role="button"
              aria-label={`Page ${index + 1}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}

        {/* Next Page */}
        <MDBPaginationItem disabled={currentPage === pagesCount}>
          <MDBPaginationLink
            role="button"
            aria-label="Next page"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, pagesCount))
            }
          >
            »
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
    </nav>
  );
}
