import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBBtn,
  MDBCheckbox,
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, registerUser } from "../features/user/userSlice";

function LoginSignup() {
  const navigate = useNavigate();

  const { first_name } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const userCookie = new Cookies();

  const [givenEmail, setGivenEmail] = useState("");
  const [givenPassword, setGivenPassword] = useState("");
  const [givenUsername, setGivenUsername] = useState("");
  const [givenName, setGivenName] = useState("");

  const [justifyActive, setJustifyActive] = useState("tab2");

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const handleRegister = () => {
    const name = givenName.split(" ");
    if (
      givenEmail === "" ||
      givenPassword === "" ||
      givenUsername === "" ||
      name[0] === "" ||
      name[1] === ""
    ) {
      return;
    }

    dispatch(
      registerUser({
        username: givenUsername,
        email: givenEmail,
        password: givenPassword,
        first_name: name[0],
        last_name: name[1],
      })
    );

    handleJustifyClick("tab1");
  };

  const handleLogin = async () => {
    const actionResult = await dispatch(
      loginUser({ email: givenEmail, password: givenPassword })
    );

    if (actionResult && actionResult.payload && actionResult.payload.access) {
      navigate("/apply-form");
    }
  };

  if (userCookie.get("access")) {
    navigate("/");
    return;
  }

  if (first_name) {
    return (
      <div className="text-center">
        <button className="m-4 w-50" onClick={() => navigate("/")}>
          Home
        </button>
      </div>
    );
  }

  return (
    <div className="py-8 sm:py-12 md:py-16 bg-gray-100">
      <div className="container">
        <MDBContainer fluid className="max-w-[600px] mx-auto">
          <MDBTabs
            pills
            justify
            className="mb-3 d-flex flex-row justify-content-between"
          >
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab1")}
                active={justifyActive === "tab1"}
                style={{
                  color: justifyActive === "tab1" ? "white" : "#414242",
                  fontWeight: "bold",
                  backgroundColor:
                    justifyActive === "tab1" ? "#709dff" : "#d4e2ff",
                }}
              >
                Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab2")}
                active={justifyActive === "tab2"}
                style={{
                  color: justifyActive === "tab2" ? "white" : "#414242",
                  fontWeight: "bold",
                  backgroundColor:
                    justifyActive === "tab2" ? "#709dff" : "#d4e2ff",
                }}
              >
                Register
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent className="bg-white p-4 rounded-md">
            <MDBTabsPane show={justifyActive === "tab1"}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                action=""
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email address"
                  id="form1"
                  type="email"
                  value={givenEmail}
                  onChange={(e) => setGivenEmail(e.target.value)}
                  required
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Password"
                  id="form2"
                  type="password"
                  value={givenPassword}
                  onChange={(e) => setGivenPassword(e.target.value)}
                  required
                />

                <div className="d-flex justify-content-between mx-4 mb-4 ">
                  <Link className="" to="/passwordreset">
                    Forgot password?{" "}
                  </Link>
                </div>
                <button className="mb-4 w-100" onClick={handleLogin}>
                  Sign in
                </button>
              </form>
              <p className="text-center">
                Not a member?{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setJustifyActive("tab2");
                  }}
                >
                  Register
                </span>
              </p>
            </MDBTabsPane>

            {/* here is the register sections */}

            <MDBTabsPane show={justifyActive === "tab2"}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                action=""
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="Name"
                  id="form1"
                  type="text"
                  value={givenName}
                  onChange={(e) => setGivenName(e.target.value)}
                  required={true}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Username"
                  id="form1"
                  type="text"
                  value={givenUsername}
                  onChange={(e) => setGivenUsername(e.target.value)}
                  required={true}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email"
                  id="form1"
                  type="email"
                  value={givenEmail}
                  onChange={(e) => setGivenEmail(e.target.value)}
                  required={true}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Password"
                  id="form1"
                  type="password"
                  value={givenPassword}
                  onChange={(e) => setGivenPassword(e.target.value)}
                  required={true}
                />

                <div className="d-flex justify-content-center mb-4">
                  <MDBCheckbox
                    name="flexCheck"
                    id="flexCheckDefault"
                    label="I have read and agree to the terms"
                  />
                </div>

                <MDBBtn className="mb-4 w-100" onClick={handleRegister}>
                  Sign up
                </MDBBtn>
              </form>
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBContainer>
      </div>
    </div>
  );
}

export default LoginSignup;
