import React from "react";
import AboutSection from "../components/AboutSection";

const AboutUs = () => {
  return (
    <div className="bg-gray-100">
      <AboutSection />
      {/* <div className=" bg-gray-100">
        <TeamMember />
        <PasswordResetPage />
      </div> */}
    </div>
  );
};

export default AboutUs;
