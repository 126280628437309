import axios from "axios";
import { MDBInput } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import Pagination from "./Pagination";

const TableData = ({ data, index }) => {
  return (
    <tr className="border-b">
      <td className="py-3">{index + 1}</td>
      <td className="py-3">{data.first_name + " " + data.last_name}</td>
      <td className="py-3">{data.contact}</td>
      <td className="py-3">{data.age}</td>
      <td className="py-3">{data.current_address}</td>
      <td className="py-3">{data.profession}</td>
      <td className="py-3">{data.bloodGroup}</td>
      <td className="py-3">{data.permanent_address.municipality}</td>
      <td className="py-3">{data.permanent_address.ward}</td>
    </tr>
  );
};

function VolunteerSection() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [nameSearchQuery, setNameSearchQuery] = useState("");
  const [bloodGroupSearchQuery, setBloodGroupSearchQuery] = useState("");
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [municipalitiesVdcSearchQuery, setMunicipalitiesVdcSearchQuery] =
    useState("");
  const itemsPerPage = 5;
  const [isFiltered, setIsFiltered] = useState(false);

  const handleFilter = () => {
    setIsFiltered(true);
  };

  // Fetch data
  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/people/volunteers/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data); // Initialize filteredData
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Filter data based on search queries
  useEffect(() => {
    const filtered = data.filter((item) => {
      const name = item.first_name + " " + item.last_name;
      const nameMatch = name
        .toLowerCase()
        .includes(nameSearchQuery.toLowerCase());

      const addressMatch = item.current_address
        .toLowerCase()
        .includes(addressSearchQuery.toLowerCase());

      const bloodGroupMatch = item.bloodGroup
        .toLowerCase()
        .includes(bloodGroupSearchQuery.toLowerCase());
      const municipalitiesVdcMatch = item.current_address
        .toLowerCase()
        .includes(municipalitiesVdcSearchQuery.toLowerCase());

      if (
        nameSearchQuery === "" ||
        bloodGroupSearchQuery === "" ||
        municipalitiesVdcSearchQuery === "" ||
        addressSearchQuery === ""
      ) {
        setIsFiltered(false);
      } else {
        setIsFiltered(true);
      }
      return (
        nameMatch && bloodGroupMatch && municipalitiesVdcMatch && addressMatch
      );
    });
    setFilteredData(filtered);
  }, [
    data,
    nameSearchQuery,
    bloodGroupSearchQuery,
    municipalitiesVdcSearchQuery,
    addressSearchQuery,
  ]);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <h2 className="text-3xl font-bold mb-3 text-center text-primary-dark">
          Brave Youth For Nation
        </h2>
        <p className="text-lg font-semibold text-center mb-4">
          "Thank you! Young Generation for your kind support and love for the
          nation."
        </p>
        <div className="flex flex-col mb-4 md:flex-row md:space-x-4">
          <MDBInput
            size="md"
            type="text"
            placeholder="Search by Name"
            value={nameSearchQuery}
            label="Search by Name"
            onChange={(e) => setNameSearchQuery(e.target.value)}
            className="focus:placeholder-gray-100"
          />
          <MDBInput
            type="text"
            placeholder="Search by Blood Group"
            value={bloodGroupSearchQuery}
            label="Search by Blood Group"
            onChange={(e) => setBloodGroupSearchQuery(e.target.value)}
            className="focus:placeholder-gray-100"
          />
          <MDBInput
            type="text"
            placeholder="Search by Municipalities/Vdc"
            value={municipalitiesVdcSearchQuery}
            label="Search by Municipalities/Vdc"
            onChange={(e) => setMunicipalitiesVdcSearchQuery(e.target.value)}
            className="focus:placeholder-gray-100"
          />
          <MDBInput
            type="text"
            placeholder="Search by Address"
            value={addressSearchQuery}
            label="Search by Address"
            onChange={(e) => setAddressSearchQuery(e.target.value)}
            className="focus:placeholder-gray-100"
          />
        </div>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse text-center">
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="py-2">S.N</th>
                <th className="py-2">Name</th>
                <th className="py-2">Contact</th>
                <th className="py-2">Age</th>
                <th className="py-2">Temporary Address</th>
                <th className="py-2">Profession</th>
                <th className="py-2">Blood Group</th>
                <th className="py-2">Municipalities/Vdc</th>
                <th className="py-2">Ward Number</th>
              </tr>
            </thead>
            <tbody>
              {!isFiltered
                ? filteredData
                    .slice(0, itemsPerPage)
                    .map((item, index) => (
                      <TableData data={item} index={index} key={index} />
                    ))
                : data
                    .slice(0, itemsPerPage)
                    .map((item, index) => (
                      <TableData data={item} key={index} />
                    ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 w-full">
          <Pagination
            data={isFiltered ? filteredData : data}
            setData={setFilteredData}
            pageSize={itemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default VolunteerSection;
