import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import BloodDonorForm from "./BloodDonorForm";
import VolunteerForm from "./VolunteerForm";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";

export const ApplyForm = () => {
  const location = useLocation();
  const [justifyActive, setJustifyActive] = useState("doner");

  const cookie = new Cookies();

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };
  useEffect(() => {
    setJustifyActive(location.state?.value || "doner");
  }, [location.state?.value]);

  return (
    <div className="bg-gray-100 py-8">
      <div className="container">
        {!cookie.get("access") ? (
          <p className="text-sm mb-2 font-bold text-center text-red-400">
            Please sign up and log in before filling out this form / कृपया यो
            फाराम भर्नु अघि साइन अप गर्नुहोस् र लग इन गर्नुहोस्।
          </p>
        ) : (
          <p className="text-md font-bold text-center mb-2">
            Welcome! You can now fill out the form.
          </p>
        )}
        <MDBTabs pills className="justify-center gap-4">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleJustifyClick("doner")}
              active={justifyActive === "doner"}
              style={{
                color: justifyActive === "doner" ? "white" : "#414242",
                fontWeight: "bold",
                backgroundColor:
                  justifyActive === "doner" ? "#709dff" : "#d4e2ff",
              }}
            >
              Apply As Blood Donor:-Inside Achham
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleJustifyClick("volunteer")}
              active={justifyActive === "volunteer"}
              style={{
                color: justifyActive === "volunteer" ? "white" : "#414242",
                fontWeight: "bold",
                backgroundColor:
                  justifyActive === "volunteer" ? "#709dff" : "#d4e2ff",
              }}
            >
              Apply As Volunteer:-Outside Achham
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <div>
          {justifyActive === "doner" && (
            <BloodDonorForm isLogin={cookie.get("access")} />
          )}
          {justifyActive === "volunteer" && (
            <VolunteerForm isLogin={cookie.get("access")} />
          )}
        </div>
      </div>
    </div>
  );
};
