import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";

const EmergencyDonors = () => {
  const [data, setData] = useState([]);
  const [emergencyDonerGroupInfo, setemergencyDonerGroupInfo] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [selectedEmergencyDonors, setSelectedEmergencyDonors] = useState("");
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [quickContacts, setQuickContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const fullPath = location.pathname;

  const regex = new RegExp("/emergencydonor/", "g");
  var emergencyGroupId = fullPath.replace(regex, "");

  //handle changes here
  const handleEmergencydonorChange = (event) => {
    const selectedEmergencyOrganization = emergencyDonerGroupInfo.find(
      (item) => item.profession === event.target.value
    );
    if (selectedEmergencyOrganization !== selectedEmergencyDonors) {
      setSelectedEmergencyDonors(selectedEmergencyOrganization);
      setCurrentPage(1);
    }
  };
  const handleBloodGroupChange = (event) => {
    setSelectedBloodGroup(event.target.value);
    setCurrentPage(1);
  };
  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when search query changes
  };

  //paginations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    console.log("Fetching data for page", currentPage);
  }, [currentPage]);

  console.log(emergencyDonerGroupInfo);

  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");

    axios
      .get(`${process.env.REACT_APP_API_URL}/store/emergency-donors/`, {
        headers: {
          Authorization: `BB ` + accessToken,
        },
      })
      .then((response) => {
        setemergencyDonerGroupInfo(response.data);
        var bloodList = response.data.map((item) => item.bloodGroup);
        bloodList.unshift("All");
        setBloodGroupList(bloodList);
        if (emergencyGroupId) {
          setSelectedEmergencyDonors(
            response.data.find((item) => item.id === parseInt(emergencyGroupId))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const url = selectedEmergencyDonors.id
      ? `${process.env.REACT_APP_API_URL}/store/emergency-donors/${selectedEmergencyDonors.id}/members/`
      : `${process.env.REACT_APP_API_URL}/store/emergency-donors/${emergencyGroupId}/members/`;
    axios
      .get(url)
      .then((response) => {
        setData(response.data);
        var bloodList = response.data.map((item) => item.bloodGroup);
        bloodList.unshift("All");
        setBloodGroupList(bloodList);
        if (!bloodList.includes(selectedBloodGroup)) {
          setSelectedBloodGroup("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedEmergencyDonors]);
  //Quick contacts Section
  useEffect(() => {
    if (selectedEmergencyDonors) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/store/emergency-donors/${selectedEmergencyDonors.id}/quick-contacts/`
        )
        .then((response) => {
          setQuickContacts(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedEmergencyDonors]);

  return (
    <section className="px-4  mt-4 ">
      <div className=" mt-4">
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0 mb-4">
          <div className="relative ">
            {/* Add search input */}
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by Blood Group..."
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500 transition-colors duration-300 
      sm:w-32 md:w-48 lg:w-64 xl:w-80  "
            />
          </div>

          <div className="">
            <select
              value={selectedEmergencyDonors.profession}
              onChange={handleEmergencydonorChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option value=" " disabled>
                {" "}
                - Select Emergency Donors -
              </option>
              {/* {emergencydonergroup_info.map((item, index) => { */}
              {emergencyDonerGroupInfo.map((item, index) => {
                return <option key={index}>{item.profession}</option>;
              })}
            </select>
          </div>
          <div className="">
            <select
              value={selectedBloodGroup}
              onChange={handleBloodGroupChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option value=" " disabled>
                {" "}
                - Blood Group -
              </option>
              {bloodGroupList.map((item, index) => {
                return <option key={index}>{item}</option>;
              })}
            </select>
          </div>
          <div className="">
            <select
              // value={selectedBloodGroup}
              // onChange={handleBloodGroupChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option> - Quick Contact -</option>
              {quickContacts.map((contact) => {
                return (
                  <option key={contact.id}>
                    {`${contact.name}: ${contact.contact}`}{" "}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto bg-gray-100 ">
        <table className="w-full h-full border-collapse text-center ">
          <thead className="thead bg-indigo-500 text-white">
            <tr>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                SN
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Name
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Age
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Blood Group
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Contact No
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Address
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Workplace
              </th>
            </tr>
          </thead>
          <tbody className="tbody divide-y divide-gray-200">
            {data.map((item, index) => {
              const itemIndex = indexOfFirstItem + index + 1;
              if (
                selectedBloodGroup === "" ||
                selectedBloodGroup === "All" ||
                item.bloodGroup === selectedBloodGroup
              ) {
                return (
                  <tr key={index}>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {itemIndex}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.name}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.age}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.bloodGroup}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.contact}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.current_address}
                    </td>
                    <td className="py-2 px-4 text-center md:sticky md:left-0 bg-white md:bg-transparent">
                      {item.workplace}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      {data.length > itemsPerPage && (
        <ul className="flex  items-center justify-end  mt-4 md:mt-0 md:space-x-4 lg:space-x-0">
          <li>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-2 border border-gray-300 rounded-md disabled:opacity-50 transition-colors duration-300 focus:outline-none hover:bg-gray-100"
            >
              <FaAngleLeft />
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <li key={page}>
                <button
                  className={`px-4 py-2 border border-gray-300 rounded-md ${
                    page === currentPage
                      ? "bg-indigo-500 text-white"
                      : "hover:bg-gray-100"
                  } transition-colors duration-300 focus:outline-none`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              </li>
            )
          )}
          <li>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-2 border border-gray-100 rounded-md disabled:opacity-50 transition-colors duration-300 focus:outline-none hover:bg-gray-100"
            >
              <FaAngleRight />
            </button>
          </li>
        </ul>
      )}
    </section>
  );
};

export default EmergencyDonors;
