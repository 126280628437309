import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PasswordResetPage = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const sendOTP = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/core/create-otp/`, {
        email: email,
        new_password: password,
      })
      .then((response) => {
        if (response.status == 200) {
          setStep(2);
        }
      })
      .catch((e) => {
        {
          alert(e.response.data.detail);
        }
      });
  };

  const verifyOTP = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/core/confirm-otp/`, {
        otp_code: otp,
      })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          //   redirect("/login");
          navigate("/login");
          alert("Password successfully changed!");
        }
      })
      .catch((e) => {
        {
          alert(e.response.data.detail);
        }
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    sendOTP();
  };

  return (
    <div className="w-full max-w-xs mx-auto mt-16 mb-4">
      <h1 className="text-2xl font-bold mb-4 mt-4">Password Reset</h1>
      {step === 1 && (
        <form onSubmit={handleFormSubmit}>
          {/* <form> */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter your Email"
              className="w-full border rounded py-2 px-3 text-gray-700"
            />
            <label className="block text-gray-700 text-sm font-bold mb-2">
              New Password
            </label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter your new Password"
              className="w-full border rounded py-2 px-3 text-gray-700"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Change Password
          </button>
        </form>
      )}
      {step === 2 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            verifyOTP();
          }}
        >
          {/* <form> */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Enter OTP
            </label>
            <input
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              className="w-full border rounded py-2 px-3 text-gray-700"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Verify OTP
          </button>
        </form>
      )}
    </div>
  );
};

export default PasswordResetPage;
