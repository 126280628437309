import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";

const VideoSection = () => {
  const videos = [
    "VwL_gQJgnyo", // YouTube Video ID 1
    // "PqyItY0HFR4",
    // "PqyItY0HFR4",
  ];
  // const setting = {
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: false,
  //   arrows: true,
  //   infinity: true,
  //   autoplay: true,
  //   speed: 300000,

  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //   ],
  // };
  return (
    <div className="bg-gray-100 pt-16 ">
      <div className="container">
        <h2 className="text-3xl font-bold text-center mb-4 text-blue-600">
          Blood Donation Awareness Video{" "}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
          {/* <Slider className="grid sm:grid-cols lg:grid-cols gap-4" {...setting}> */}
          {videos.map((videoId, index) => (
            <div key={index} className="border rounded-lg overflow-hidden">
              <iframe
                className="w-full h-80"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
                title={`Video ${index + 1}`}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          ))}
          {/* </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
