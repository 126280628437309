import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="bg-zinc-100 md:pt-4 lg:pt-16 ">
      <div className="container">
        <div className="w-full flex flex-col justify-between">
          <div className="grid md:grid-cols-2 gap-3">
            <div className="flex flex-col md:items-start w-full pe-2 py-4 md:py-8">
              <p className="text-l md:text-1xl lg:text-1xl mt-2 italic text-blue-600">
                Pulsating with Kindness
              </p>
              <h1 className="text-2xl md:text-xl lg:text-3xl  font-bold text-blue-600">
                Lifeline Achham
              </h1>
              <p className="text-base md:text-lg lg:text-l mt-2 md:mt-6">
                <strong className="text-black-800">Lifeline Achham </strong> is
                a web application that contains information about active blood
                donors in Achham. These donors are prepared to donate their
                blood to assist individuals in need during emergencies. For now,
                Bayalpata Hospital and Achham District Hospital will be the
                primary user of this web application, while the people of Achham
                will serve as the blood donors. This project aims to foster
                unity among the youth and engage all responsible individuals in
                Achham to work collaboratively for its improvement and the
                well-being of its residents.
              </p>
              <Link to="/apply-achhamblooddonor">
                <button className="mt-6 px-6 py-3 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
                  Donate Now
                </button>
              </Link>
            </div>
            <div className="mt-6 md:mt-0  ">
              <img
                className="w-full h-full object-cover rounded-lg mt-0 mb-4 "
                src="https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2023-01/230127-blood-donation-al-0933-067298.jpg"
                alt="images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
