import axios from "axios";
import React, { useEffect, useState } from "react";

const startDate = new Date("2023-01-01T00:00:00Z");

const CounterSection = () => {
  const [milestoneData, setMilestoneData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/people/get_milestones/`)
      .then((res) => {
        console.log(res);
        const data = res.data;
        setMilestoneData([
          {
            value: `${new Date().getFullYear() - startDate.getFullYear()}+`,
            label: "Year of Services",
          },
          { value: `${data["active_donors_count"]}+`, label: "Active Donors" },
          { value: `${data["volunteer_count"]}+`, label: "Youth Volunteers" },
          { value: `${data["donors_till_date"]}+`, label: "Donor till Date" },
          {
            value: `${data["total_blood_recipients"]}+`,
            label: "Happy Blood Recipients",
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-gray-100 w-full pt-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-center mb-2  text-blue-600">
            Milestone till Now
          </h2>
          <p className="text-xl py-2 text-gray-800 mb-4">
            "Every drop of blood given is a symphony of humanity's strength, and
            every grateful recipient becomes a living melody of our shared
            dedication."
          </p>
        </div>
        <div className="grid md:grid-cols-5 gap-4 text-center ">
          {milestoneData.map((milestone, index) => (
            <div key={index} className="border py-8 rounded-xl shadow-xl">
              <p className="text-6xl font-bold text-indigo-600">
                {milestone.value}
              </p>
              <p className="text-gray-400 mt-2">{milestone.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
