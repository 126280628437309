import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const GalleryDetailPage = () => {
  // Sample image data (replace with your actual image URLs)

  const [images, setImages] = useState([]);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const params = useParams();

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/store/galleries/${params.id}/images/`
      )
      .then((res) => {
        res.data.map((image) => {
          console.log(`${process.env.REACT_APP_API_URL}/store/${image.image}`);
        });
        setImages(
          res.data.map((image) => {
            return `${process.env.REACT_APP_API_URL}/${image.image}`;
          })
        );
        console.log(images);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);

  return (
    <div className="py-8 sm:py-12 md:py-16">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="overflow-hidden bg-gray-100 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 cursor-pointer"
              onClick={() => openModal(index)}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>

        {selectedImageIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="max-w-3xl mx-auto flex relative">
              <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 m-4 text-white text-xl"
                onClick={prevImage}
              >
                &lt;
              </button>
              <img
                src={images[selectedImageIndex]}
                alt={`Image ${selectedImageIndex + 1}`}
                className="w-full h-auto object-contain"
              />
              <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 m-4 text-white text-xl"
                onClick={nextImage}
              >
                &gt;
              </button>
              <button
                className="absolute top-0 right-0 m-4 text-white text-xl"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryDetailPage;
