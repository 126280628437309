import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const AboutSection = () => {
  return (
    <div className="w-full bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-4">
          <img
            className="w-full h-[auto] mx-auto rounded-lg"
            src="https://th.bing.com/th/id/OIP.6Q4HuXyY3XNt51DdzI2fmgHaEK?pid=ImgDet&rs=1"
            alt="Images Looding Soon"
          />
          <div className="flex flex-col md:items-start w-full px-2">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold mb-4 md:items-start w-full  text-blue-600">
              Message From Lifeline Achham
            </h1>
            <p className="flex flex-col justify-center md:items-start w-full pe-2">
              Today, we honor the LifeLine Achham community, a group dedicated
              to tirelessly working every day and making personal sacrifices to
              uplift those in need. Our project serves as a beacon of hope,
              providing vital information to those who require blood, regardless
              of type. This initiative is a step towards ensuring that every
              young person in the rural areas of Achham District receives timely
              medical care, bringing healthcare closer to home and strengthening
              our community
            </p>
            <p className="font-bold text-2xl text-blue-600 pt-2">
              Ganesh Kunwar
            </p>
            <p className="font-semibold">Founder/ Software Developer</p>
            <div className="flex justify-start gap-3 pt-2">
              <Link
                href="https://www.facebook.com/ganesh.kunwar.710"
                className="hover:text-primary-dark"
              >
                <FaFacebookF />
              </Link>
              <Link className="hover:text-primary-dark">
                <FaTwitter />
              </Link>
              <Link className="hover:text-primary-dark">
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>

        {/* contents here  */}
        <div className="pt-8 sm:pt-12 md:pt-16">
          <h2>This is the content.</h2>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
