import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const fetchData = async (url, token = null) => {
  try {
    const response = await axios.get(url, {
      headers: token ? { Authorization: `BB ${token}` } : undefined,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return { error: true, message: error.message };
  }
};

const Dropdown = ({ title, options, selectedOption, onSelect, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="w-full md:w-64">
      <label className="block font-bold mb-2">{title}:</label>
      <div
        onClick={toggleDropdown}
        className="block appearance-none w-full border border-gray-300 rounded px-4 py-2 cursor-pointer"
      >
        {selectedOption?.name || (isLoading ? "Loading..." : `Select ${title}`)}
        <span className="ml-1">&#9660;</span>
      </div>
      {isOpen && !isLoading && (
        <ul className="dropdown-content bg-gray-100 border border-gray-300 rounded mt-1 py-1 max-h-60 overflow-auto shadow-lg">
          {options.length > 0 ? (
            options.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  onSelect(option);
                  setIsOpen(false);
                }}
              >
                {option.name}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500">No options available</li>
          )}
        </ul>
      )}
    </div>
  );
};

const BloodDoners = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    municipalities: { items: [], isLoading: true, error: null },
    hospitals: { items: [], isLoading: true, error: null },
    emergencyDonors: { items: [], isLoading: true, error: null },
    associateDonors: { items: [], isLoading: true, error: null },
  });

  const [dropdownState, setDropdownState] = useState({
    selectedMunicipality: null,
    selectedHospital: null,
    selectedEmergencyDonor: null,
    selectedPatners: null,
    selectedRecentDonors: null,
  });

  const handleSelect = (field, option) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [field]: option,
    }));
    if (option?.link) {
      navigate(option.link);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const userCookie = new Cookies();
      const accessToken = userCookie.get("access");

      const loadAndSetData = async (key, url, mapFn) => {
        setData((prev) => ({
          ...prev,
          [key]: { ...prev[key], isLoading: true },
        }));

        const result = await fetchData(url, accessToken);
        if (result.error) {
          setData((prev) => ({
            ...prev,
            [key]: { items: [], isLoading: false, error: result.message },
          }));
        } else {
          setData((prev) => ({
            ...prev,
            [key]: {
              items: result.map(mapFn),
              isLoading: false,
              error: null,
            },
          }));
        }
      };

      await loadAndSetData(
        "municipalities",
        `${process.env.REACT_APP_API_URL}/store/municipalities/`,
        (item) => ({
          ...item,
          link: `/municipalityvdc/${item.name}/${item.id}`,
        })
      );

      await loadAndSetData(
        "hospitals",
        `${process.env.REACT_APP_API_URL}/store/associate-hospitals/`,
        (item) => ({
          ...item,
          link: `/hospitaldonor/${item.id}`,
        })
      );

      await loadAndSetData(
        "emergencyDonors",
        `${process.env.REACT_APP_API_URL}/store/emergency-donors/`,
        (item) => ({
          ...item,
          name: item.profession,
          link: `/emergencydonor/${item.id}`,
        })
      );

      await loadAndSetData(
        "associateDonors",
        `${process.env.REACT_APP_API_URL}/store/associates/`,
        (item) => ({
          ...item,
          link: `/associatedonergroup/${item.id}`,
        })
      );
    };

    loadData();
  }, []);

  return (
    <div className="flex flex-col md:flex-row justify-center gap-4 py-8 mx-4">
      <Dropdown
        title="Municipality/VDCs"
        options={data.municipalities.items}
        isLoading={data.municipalities.isLoading}
        selectedOption={dropdownState.selectedMunicipality}
        onSelect={(option) => handleSelect("selectedMunicipality", option)}
      />

      <Dropdown
        title="Hospital"
        options={data.hospitals.items}
        isLoading={data.hospitals.isLoading}
        selectedOption={dropdownState.selectedHospital}
        onSelect={(option) => handleSelect("selectedHospital", option)}
      />

      <Dropdown
        title="Emergency Donors"
        options={data.emergencyDonors.items}
        isLoading={data.emergencyDonors.isLoading}
        selectedOption={dropdownState.selectedEmergencyDonor}
        onSelect={(option) => handleSelect("selectedEmergencyDonor", option)}
      />

      <Dropdown
        title="Associate Volunteer"
        options={data.associateDonors.items}
        isLoading={data.associateDonors.isLoading}
        selectedOption={dropdownState.selectedPatners}
        onSelect={(option) => handleSelect("selectedPatners", option)}
      />

      <Dropdown
        title="Recent Donors / Volunteers"
        options={[
          { name: "Recent Donors", link: "/recentdonors" },
          { name: "Donors till Date", link: "/donortilldate" },
          { name: "Volunteers", link: "/volunteer" },
        ]}
        selectedOption={dropdownState.selectedRecentDonors}
        onSelect={(option) => handleSelect("selectedRecentDonors", option)}
      />
    </div>
  );
};

export default BloodDoners;
