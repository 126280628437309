import axios from "axios";
import React, { useState } from "react";

import GalleryCard from "./GalleryCard";

const GallerySection = () => {
  const [galleryData, setGalleryData] = useState([]);

  useState(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/galleries/`)
      .then((response) => {
        setGalleryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <h2 className="text-3xl font-bold text-center mb-4 text-blue-600">
          Images Gallery
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {galleryData.map((gallery) => (
            <GalleryCard
              // date={gallery.created_at}
              date={new Date(gallery.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              key={gallery.title}
              imageSrc={gallery.thumbnail}
              title={gallery.title}
              gallery={gallery}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
