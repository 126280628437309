import axios from "axios";
import React, { useEffect, useState } from "react";

function DonorTillDate() {
  const [data, setData] = useState([]);
  const [nameSearchQuery, setNameSearchQuery] = useState("");
  const [bloodGroupSearchQuery, setBloodGroupSearchQuery] = useState("");

  const handleNameSearchChange = (e) => {
    setNameSearchQuery(e.target.value);
  };

  const handleBloodGroupSearchChange = (e) => {
    setBloodGroupSearchQuery(e.target.value);
  };

  const filteredData = data.filter((item) => {
    const name = item.first_name + " " + item.last_name;
    const nameMatch = name
      .toLowerCase()
      .includes(nameSearchQuery.toLowerCase());
    const bloodGroupMatch = item.bloodGroup
      .toLowerCase()
      .includes(bloodGroupSearchQuery.toLowerCase());
    return nameMatch && bloodGroupMatch;
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/people/all_donors/`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="px-4 py-8 md:px-8 mt-2">
      <h2 className="text-3xl font-bold mt-2 mb-4 text-center">Brave Heroes</h2>
      <div className="overflow-x-auto">
        <div className="mb-4">
          {/* Name Search Input */}
          <input
            type="text"
            value={nameSearchQuery}
            onChange={handleNameSearchChange}
            placeholder="Search by name..."
            className="w-30 px-4 py-2 border rounded-lg"
          />
          {/* Blood Group Search Input */}
          <input
            type="text"
            value={bloodGroupSearchQuery}
            onChange={handleBloodGroupSearchChange}
            placeholder="Search by blood group..."
            className="w-30 ml-4 px-4 py-2 border rounded-lg"
          />
        </div>
        <table className="w-full border-collapse text-center">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="py-2">S.N</th>
              <th className="py-2">Name</th>
              <th className="py-2">Contact Number</th>
              <th className="py-2">Age</th>
              <th className="py-2">Address</th>
              <th className="py-2">Workplace</th>
              <th className="py-2">Blood Group</th>
              {/* <th className="py-2">Donate Date</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index} className="border">
                <td className="py-2">{index + 1}</td>
                <td className="py-2">
                  {item.first_name + " " + item.last_name}
                </td>
                <td className="py-2">{item.contact}</td>
                <td className="py-2">{item.age}</td>
                <td className="py-2">{`${item.permanent_address.tole}-${item.permanent_address.ward},${item.permanent_address.municipality}`}</td>
                <td className="py-2">{item.workplace}</td>
                <td className="py-2">{item.bloodGroup}</td>
                {/* <td className="py-2">{item.date}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DonorTillDate;
